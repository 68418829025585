import { useState } from 'react';
import './Login.scss';
import { Image, Typography, Input, Button, message } from 'antd';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import * as authActions from '../../redux/actions/auth';
import vaytiennhanh from '../../assets/uu-dai-removebg-preview.png';
import logo from '../../assets/mb-bank-logo-inkythuatso-01-10-09-01-10-removebg-preview.png';
import _buildings from '../../assets/bgg.jpg';
import { motion } from 'framer-motion';
export default () => {
  const search = window.location.search;
  const params = new URLSearchParams(search);

  const history = useHistory();
  const dispatch = useDispatch();
  const [state, setState] = useState({
    phone: params.get('phone') || '',
    password: '',
  });
  const [loading] = useState(false);

  async function confirmLogin() {
    if (!state.phone || !state.password) {
      message.error('Không được để trống thông tin.');
      return;
    }
    dispatch(authActions.Login(state));
  }

  return (
    <div
      className="login-container"
      style={{
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.75) 0%, rgb(255, 255, 255) 100%), url('${_buildings}')`,

        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'bottom center',
      }}
    >
      <motion.div
        className="empty-div"
        initial={{
          opacity: 0.4,
          scale: 0.35,
        }}
        transition={{ duration: 0.6 }}
        animate={{ opacity: 1, scale: 1 }}
      >
        <Image
          src={logo}
          width={'180px'}
          style={{
            marginBottom: '20px',
            marginTop: '50px',
            borderRadius: '10px',
          }}
          preview={false}
        />
      </motion.div>
      <div className="form">
        <div className="form-header" style={{ marginBottom: '15px' }}>
          <Typography.Text
            className="title"
            style={{
              color: '#666666',
              textTransform: 'uppercase',
            }}
          >
            Doanh nghiệp số - Trải nghiệm số
          </Typography.Text>
        </div>
        <div className="form-body">
          <Input
            className="input"
            size="large"
            autoFocus={true}
            placeholder="Nhập số điện thoại"
            value={state.phone}
            onChange={(e) => setState({ ...state, phone: e.target.value })}
          />

          <Input
            className="input"
            size="large"
            placeholder="Nhập mật khẩu"
            type="password"
            value={state.password}
            onChange={(e) => setState({ ...state, password: e.target.value })}
          />

          <Button
            className="login-btn"
            onClick={confirmLogin}
            loading={loading}
            size="large"
          >
            <Typography.Text style={{ color: '#fff', fontSize: '16px' }}>
              Đăng nhập
            </Typography.Text>
          </Button>
          <div
            className="form-footer"
            style={{ textAlign: 'center' }}
            onClick={() => history.push('/auth/signup')}
          >
            <Typography.Link style={{ fontSize: 15, color: '#333' }}>
              Chưa có tài khoản ? 👉{' '}
              <b style={{ color: '#141ed2' }}>Đăng ký ngay !</b>
            </Typography.Link>
            <Image
              src={vaytiennhanh}
              //   width={'90%'}
              style={{
                maxWidth: '300px',
                marginBottom: '20px',
                marginTop: '30px',
              }}
              preview={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
