import React, { useState, useEffect } from 'react';
import { Typography, Avatar, message, Button, Image } from 'antd';
import tickAsset1 from '../../assets/tick.e14cb23e178b8d3acd8f.png';
import tickAsset2 from '../../assets/tick.e14cb23e178b8d3acd8f34.png';
import {
  ExclamationCircleOutlined,
  AlertFilled,
  UserOutlined,
  LogoutOutlined,
  CustomerServiceFilled,
  DollarCircleOutlined,
  BankOutlined,
} from '@ant-design/icons';
import { motion } from 'framer-motion';
import * as actions from '../../redux/actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { facebookSupportLinks } from '../../utils/connectCSKH';
import './User.scss';
import BankInfo from './BankInfo';
import api from '../../api';

export default function User() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector((state) => state._auth);
  const [showBankInfo, setShowBankInfo] = useState(false);
  const [currentRequest, setCurrentRequest] = useState({});
  const [showDebtBankInfo, setShowDebtBankInfo] = useState(false);
  const [selectedCSKHLink, setSelectedCSKHLink] = useState(null);
  const [loadingCSKHLink, setLoadingCSKHLink] = useState(true);
  const [banks, setBanks] = useState({});

  const handleLogout = () => {
    dispatch(actions.Logout());
  };

  const handleContactSupport = () => {
    if (selectedCSKHLink) {
      window.open(selectedCSKHLink, '_blank', 'noopener,noreferrer');
    } else {
      message.info('Đang tải liên kết hỗ trợ. Vui lòng thử lại sau.');
    }
    return;
  };

  useEffect(() => {
    //  const randomIndex = Math.floor(Math.random() * facebookSupportLinks.length);
    //  setSelectedCSKHLink(facebookSupportLinks[randomIndex]);
    //  setLoadingCSKHLink(false);
    loadProfile();
  }, []);

  const loadProfile = async () => {
    const { data } = await api.get('/users/profile');
    dispatch(actions.initialLogin(data.data));
    loadCurrentRequest();
    if (data.banks) {
      setBanks(data.banks);
    }
    if (data.data && data.data.sale) {
      setSelectedCSKHLink(data.data.sale.phone);
      localStorage.setItem('selectedLink', data.data.sale.phone);
      setLoadingCSKHLink(false);
    }
  };

  const loadCurrentRequest = async () => {
    try {
      const { data } = await api.get('/requests/lasted');
      setCurrentRequest(data?.data ? data.data : {});
    } catch (error) {
      setCurrentRequest({});
      console.log(error);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0.3, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      {/* <div className="content-container">
        {showBankInfo && !showDebtBankInfo && <BankInfo />}
        {showDebtBankInfo && (
          <div className="debt-bank-info">
            <Typography.Text strong style={{ fontWeight: 'bold' }}>
              Thông Tin Ngân Hàng Trả Nợ
            </Typography.Text>
            <br />
            <Typography.Text style={{ fontWeight: 'bold' }}>
              CÔNG TY TNHH DT TM 2 GIAY
            </Typography.Text>
            <br />
            <Typography.Text style={{ fontWeight: 'bold' }}>
              2 SECONDS JSC
            </Typography.Text>
            <br />
            <Typography.Text style={{ color: 'red', fontWeight: 'bold' }}>
              Số Tài Khoản: 6167041000012
            </Typography.Text>
            <br />
            <Typography.Text style={{ fontWeight: 'bold' }}>
              NGÂN HÀNG BẢN VIỆT (Viet Capital Bank)
            </Typography.Text>
          </div>
        )}
      </div> */}
      <div className="profile-container">
        <div className="header">
          <Typography.Text className="header-text">Hồ sơ</Typography.Text>
        </div>
        <div className="avatar">
          <Avatar
            size={120}
            src={profile?.kyc?.id_with_face || profile?.avatar}
          />
          <Typography.Text strong style={{ fontSize: 23, color: '#121212' }}>
            {profile?.kyc?.name}
          </Typography.Text>
          <Typography.Text
            style={{ fontSize: 18, color: '#666', fontWeight: 500 }}
          >
            {profile?.phone}
          </Typography.Text>
        </div>
        {!profile?.kyc?.name && <AlertVerify />}
        <div style={{ padding: 20 }}>
          {banks && (
            <Tab
              title="Xem Thông Tin Ngân Hàng Trả Nợ"
              icon={
                <BankOutlined
                  style={{ color: '#fff', fontSize: 25, marginRight: 10 }}
                />
              }
              onClick={() => {
                history.push('/bank-company');
              }}
            />
          )}
          <Tab
            title="Hợp đồng vay"
            icon={
              <DollarCircleOutlined
                style={{ color: '#fff', fontSize: 25, marginRight: 10 }}
              />
            }
            onClick={() => {
              history.push('/my-contract');
            }}
          />
          <Tab
            title="Thông tin cá nhân"
            icon={
              <UserOutlined
                style={{ color: '#fff', fontSize: 25, marginRight: 10 }}
              />
            }
            onClick={
              profile?.kyc?.name
                ? () => history.push('/detail-profile')
                : () => message.info('Bạn chưa xác minh danh tính.')
            }
          />
          {/* <Tab
            title="Thông Tin Ngân Hàng"
            icon={
              <BankOutlined
                style={{ color: '#fff', fontSize: 25, marginRight: 10 }}
              />
            }
            onClick={() => {
              history.push('/my-bank');
            }}
          /> */}

          <Tab
            title="Liên hệ tư vấn - hỗ trợ"
            icon={
              <CustomerServiceFilled
                style={{ color: '#fff', fontSize: 25, marginRight: 10 }}
              />
            }
            onClick={
              profile?.kyc?.name
                ? () =>
                    profile?.has_contract
                      ? handleContactSupport()
                      : message.info('Bạn chưa có khoản vay được tạo.')
                : () => message.info('Bạn chưa xác minh danh tính.')
            }
            disabled={loadingCSKHLink}
          />
        </div>
        <div className="log-out">
          <Button
            className="log-out-btn"
            icon={
              <LogoutOutlined
                style={{ fontSize: 25, color: '#fff', height: 25 }}
              />
            }
            style={{
              fontSize: '14px !important',
              lineHeight: '25px !important',
            }}
            onClick={handleLogout}
          >
            ĐĂNG XUẤT
          </Button>
        </div>
        <div
          style={{
            padding: '10px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <div style={{ padding: '0 10px' }}>
            <Image src={tickAsset1} preview={false} style={{ maxWidth: 100 }} />
            <Image src={tickAsset2} preview={false} style={{ maxWidth: 100 }} />
          </div>
          <Typography.Text
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: '#555',
              textAlign: 'center',
              padding: '0 10px',
            }}
          >
            ® Bản quyền thuộc về Ngân Hàng Quân Đội MBBank
            <br />
            Tất cả các quyền được bảo hộ.
            <br />
            Copyright © 2023 MBBank
          </Typography.Text>
        </div>
      </div>
    </motion.div>
  );
}

const Tab = ({ title, onClick, icon, disabled }) => {
  return (
    <motion.div
      whileTap={{
        scale: 0.96,
        opacity: 0.3,
        border: '1px solid rgba(0, 106, 176,0.3)',
      }}
      onClick={onClick}
      className={`tab ${disabled ? 'disabled' : ''}`}
    >
      {icon}
      <Typography.Text className="tab-text" style={{ color: '#fff' }}>
        {title}
      </Typography.Text>
    </motion.div>
  );
};

const AlertVerify = ({ status }) => {
  const history = useHistory();

  return (
    <div className="alert-container" style={{ marginTop: 0, marginBottom: 0 }}>
      <div>
        <Typography.Text strong style={{ fontSize: 17, color: '#121212' }}>
          Xác thực tài khoản
        </Typography.Text>
        <div
          style={{
            borderBottom: '1px solid rgba(0, 106, 176,0.3)',
            height: 0.1,
            width: '100%',
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '30px 10px',
          }}
        >
          <div
            style={{
              flex: 1,
              minWidth: '30%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AlertFilled style={{ fontSize: 45, color: '#333' }} />
          </div>
          <div>
            <Typography.Text
              style={{ fontSize: 16, fontWeight: 400, color: '#333' }}
            >
              Bổ sung CMND/CCCD và chân dung để hoàn tất định danh
            </Typography.Text>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '0px 10px',
          }}
        >
          <motion.div
            whileTap={{ scale: 0.9, opacity: 0.3 }}
            onClick={() => history.push('/verify')}
          >
            <Typography.Text
              strong
              style={{
                textAlign: 'right',
                fontWeight: '700',
                color: '#333',
                fontSize: 17,
              }}
            >
              Xác thực ngay
            </Typography.Text>
          </motion.div>
        </div>
      </div>
    </div>
  );
};
